<template>
  <el-dialog
    append-to-body
    width="500px"
    title="反馈处理结果"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      class="staff-from"
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="100px"
      label-position="right"
      :model="form.data"
      :rules="form.rules"
      :size="subUnitSize"
    >
      <el-form-item label="是否整改" prop="is_change">
        <el-radio-group v-model="form.data.is_change">
          <el-radio :label="1">已整改</el-radio>
          <el-radio :label="2">无需整改</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="整改方式"
        prop="type"
        v-if="form.data.is_change == 1"
      >
        <el-select v-model="form.data.type" style="width: 100%">
          <el-option
            v-for="(item, index) in typeArr"
            :label="item.name"
            :value="item.name"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="情况说明"
        prop="deal"
        v-if="form.data.is_change == 2 || (form.data.is_change == 1 && form.data.type == '其他方式')"
      >
        <el-input
          v-model="form.data.deal"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 8 }"
          placeholder="请输入内容"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "WarningSellEdit",
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      is_sys: this.$store.state.is_sys, // 1:只有超级管理员才有列表的删除按钮
      visible: false,
      isChange: false,
      loading: false,
      form: {
        loading: false,
        data: {
          ids: "",
          is_change: 1, // 是否整改 1:是 2:否
          type: "",
          deal: "",
        },
        rules: {
          is_change: [{ required: true, message: "请选择", trigger: "change" }],
          type: [{ required: true, message: "请选择", trigger: "change" }],
          deal: [{ required: true, message: "请输入", trigger: "change" }],
        },
      },
      typeArr: [
        { id: 1, name: "更换供应商" },
        { id: 2, name: "要求供应商降价" },
        { id: 3, name: "收货单价有误已修改" },
        { id: 4, name: "其他方式" },
      ],
    };
  },
  methods: {
    getDetail(ids) {
      this.isChange = true;
      this.form.data.ids = ids;
    },
    reset(done) {
      this.isChange = false;
      this.$refs.elFormDom.resetFields();
      done && done();
    },
    toggle(show) {
      this.visible = show;
      !show && this.reset();
    },
    // 提交
    confirm() {
      this.$refs.elFormDom.validate((valid) => {
        if (valid) {
          let _params = {
            ids: this.form.data.ids,
            is_change: this.form.data.is_change,
            deal: this.form.data.deal || this.form.data.type,
          };
          this.form.loading = true;
          this.$http
            .post("/admin/warn/operation", _params)
            .then((res) => {
              if (res.code === 1) {
                this.toggle(false);
                this.$emit("refresh");
              }
            })
            .finally(() => {
              this.form.loading = false;
            });
        }
      });
    },
  },
};
</script>
